/* eslint-disable */
import useJwt from "@/auth/jwt/useJwt";

export const isUserLoggedIn = () => {
  return localStorage.getItem("token") !== null;
};

export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return "/home";
  if (userRole === "client") return { name: "access-control" };
  return { name: "auth-login" };
};
