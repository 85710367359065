import Vue from 'vue';
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue';

import VueCompositionAPI from '@vue/composition-api';

import VueI18n from 'vue-i18n';

import router from './router';
import store from './store';
import App from './App.vue';
// Global Components
import './global-components';

// 3rd party plugins
import '@/libs/portal-vue';
import '@/libs/toastification';
import '@/libs/vue-select';

// i18n
import i18n from './libs/i18n';

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

// import core styles
require('@core/scss/vue/libs/vue-select.scss');

Vue.config.productionTip = false;

export default new VueI18n({
	locale: 'en',
});

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount('#app');
